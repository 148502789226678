import config from "constants/config";
import httpClient from 'http-common';
import httpClientV1 from 'http-common-v1';
import * as _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Pagination from 'react-bootstrap/Pagination';
import * as moment from "moment";
import AddEditCompanies from 'components/Shared/AddEditCompanies';
import ConfirmModal from "components/Shared/ConfirmModal"
import AddEditSpeaker from 'components/Shared/AddEditSpeaker';


import {
    Button, Card, Col, Container, Dropdown, Form, Row, Table
} from "react-bootstrap";

function V1Companies() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [item, setItem] = useState([])
    const [name, setName] = useState(null)
    const [targetLang, setTargetLang] = useState(null)
    const [targetLangText, setTargetLangText] = useState(null);
    const [type, setType] = useState(1);
    const [selectItemValue, setSelectItemValue] = useState(null)
    const [message, setMessage] = useState('');
    const [modalConfirmShow, setModalConfirmShow] = useState(false);


    const [companies, setCompanies] = useState([]);
    const [totalCompanyCount, setCompanyCount] = useState(0)
    const [sourceLang, setSourceLang] = useState(config.LANGUAGES_ASSENTS_SOURCE)
    const [modalShow, setModalShow] = useState(false);
    const [speakerModalShow, setSpeakerModalShow] = useState(false);




    let isShow = false;
    let numberOfPages = 0;

    useEffect(() => {
        getAllCompanies('', 0);
    }, []);


    const getAllCompanies = async (value) => {


        try {
            let url = config.V1_AUTH_API_URL + `/companies?page=${(value != "") ? value : page}&limit=${limit}&`
            if (name != null) {
                url = url + 'search=' + name
            }

            const getAllVoice = await httpClientV1.get(url)
            const responseData = getAllVoice.data.data;
            setCompanyCount(responseData.count);
            const activeValue = (value != "") ? value : page;
            mapPagination(responseData.count, activeValue)
            setCompanies(responseData.result);
            isShow = true;
        } catch (error) {
            console.log(error)
        }

    }

    const getApiCallPage = async (value) => {
        await setPage(value);
        await getAllCompanies(value);
    }

    const mapPagination = (totalCount, active) => {
        numberOfPages = Math.ceil(totalCount / limit);
        let items = []
        for (let number = 1; number <= numberOfPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={() => getApiCallPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setItem(items);
    }

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">
                            <Card.Header>
                                <Row className="table-header-top">
                                    <Card.Title as="h4" className="card_title">Videos list</Card.Title>
                                    <Col xs={5}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control value={name} onChange={(v) => {
                                                setName(v.target.value)
                                            }}
                                                type="text" placeholder="search by title" />
                                        </Form.Group>

                                    </Col>

                                    <Col xs={3}></Col>

                                    <Col>
                                        <Button variant="primary" className="cart_title_button custom_margin" onClick={(v) => getApiCallPage(1)} >Submit</Button>
                                        <Button variant="primary" className="cart_title_button" onClick={() => {
                                            setSelectItemValue(null)
                                            setType(1);
                                            setModalShow(true)
                                        }} >Add Company</Button>
                                    </Col>

                                </Row>

                            </Card.Header>
                            <Card.Body className="table_video table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No</th>
                                            <th className="border-0">Name</th>
                                            <th className="border-0">Email</th>
                                            <th className="border-0">Domain</th>
                                            <th className="border-0">Mobile</th>
                                            <th className="border-0">Address</th>
                                            <th className="border-0">Status</th>
                                            <th className="border-0">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companies.length > 0 &&
                                            companies.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.domain}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>
                                                            {item.address && <span>{item.address}</span>}
                                                            {item.city && <span>, {item.city}</span>}

                                                            {item.state && <span>, {item.state}</span>}

                                                            {item.country && <span>, {item.country}</span>}



                                                        </td>
                                                        <td>{item.isActive ? "Enabled" : "Disabled"}</td>

                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                                    Action
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => {
                                                                        setSelectItemValue(item)
                                                                        setType(2);
                                                                        setModalShow(true)
                                                                    }}
                                                                    >Update</Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setMessage("Are you sure?. Want to delete company:- " + item.name)
                                                                            setSelectItemValue(item)
                                                                            setModalConfirmShow(true)
                                                                        }}
                                                                    >Remove</Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setSelectItemValue(item)
                                                                            setType(3);
                                                                            setSpeakerModalShow(true)
                                                                        }}
                                                                    >Add Voice</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>)

                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {item && <Row className="paginationValue">
                    <Pagination>{item}</Pagination>
                </Row>}



            </Container>


            {speakerModalShow && <AddEditSpeaker type={type} show={speakerModalShow} itemData={selectItemValue} onHide={async () => {
                getApiCallPage(1)
                setSpeakerModalShow(false);

            }}
            ></AddEditSpeaker>}


            {modalShow && <AddEditCompanies type={type} show={modalShow} itemData={selectItemValue} onHide={async () => {
                getApiCallPage(1)
                setModalShow(false);

            }}
            ></AddEditCompanies>}

            <ConfirmModal message={message} setMessage={setMessage} show={modalConfirmShow} onHide={async (number) => {
                if (number === 1) {
                    try {
                        const getAllVoice = await httpClientV1.delete(`${config.V1_AUTH_API_URL}/companies/${selectItemValue.uuid}`);
                        toast.success("Companies Update successfully");
                    } catch (error) {
                        toast.success(error?.message);
                    }
                    getApiCallPage(1);
                    setSelectItemValue(null)
                    setModalConfirmShow(false);

                } else {
                    setSelectItemValue(null)
                    setModalConfirmShow(false);
                }

            }}
            ></ConfirmModal>

        </>

    )


}

export default V1Companies;