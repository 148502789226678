import axios from "axios";
import config from "constants/config";

const httpClientV1 = axios.create({
    baseURL: process.env.BASEURL,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': '47d64e2e-e96e-11ed-a05b-0242ac120003'
    },
});

httpClientV1.interceptors.request.use(function (config) {
    const token = localStorage.getItem('accessToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

httpClientV1.interceptors.response.use(
    (res) => res,
    async (error) => {
        const originalRequest = error.config;

        // Handle 401 error: Unauthorized
        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Prevent infinite loop
            try {
                // Attempt to refresh token
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(`${config.V1_AUTH_API_URL}/token`, {
                    refreshToken: refreshToken
                });

                // Store new access token
                const { accessToken } = response.data.data.tokens;
                localStorage.setItem('accessToken', accessToken);

                // Retry original request with new token
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return httpClientV1(originalRequest);

            } catch (refreshError) {
                console.error("Refresh token failed: ", refreshError);
                // Handle token refresh failure (e.g., log out, redirect to login page)
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                window.location.href = '/auth/login'; // Redirect to login page
            }
        }

        return Promise.reject(error);
    }
);

export default httpClientV1;
