import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
// import httpClient from 'http-common'
import axios from "axios";
import config from "constants/config"
import toast from "react-hot-toast"
import httpClient from 'http-common'

// react-bootstrap components
import {
    Modal,
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";




function UpdateSpeakerModal(props) {
    const { setSelectedRow, item } = props
    const [sqsList, setSqsList] = useState(config.SQS_LISTS);
    const submitData = async(values)=> {
        const token = localStorage.getItem('token')
        try {
            const updateStatus = await httpClient.post(config.API_URL + "/admin/voices/updateStatus", values)
            closeModal(1)
            console.log("updateStatus ::::", updateStatus);
        } catch (error) {
            console.log(error)
            closeModal(0)
            console.log(error)
            toast.error(error.response?.data?.message)
        }
    }

    const closeModal = (value) => {
        setSelectedRow(null)
        props.onHide(value);
    }

    const getSQSList = async () => {
        try {
            const token = localStorage.getItem('token');
            const getSpeakerDetails = await axios.get(config.API_URL + "/admin//sqs/lists", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(getSpeakerDetails.data);
            setSqsList(getSpeakerDetails.data.data)
        } catch (error) {
            console.log(error.message)
            toast.error(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        getSQSList();

        console.log("item ::: ", item)
        
    }, []);

    return (
        <Modal {...props} size="lg" backdrop="static">
            <Formik
                initialValues={{ 
                companyCode: item.company, 
                speakerCode: item._id, 
                tts_id: (item.tts_id != undefined && item.tts_id != '') ? item.tts_id : "",
                vocoder_id: (item.vocoder_id != undefined && item.vocoder_id != '') ? item.vocoder_id : "",
                version: (item.version != undefined && item.version != '') ? String(item.version) :  "",
                demo_text:  (item.demoText != undefined && item.demoText != '') ? item.demoText : "",
                pitch:  (item.pitch != undefined && item.pitch != '') ? item.pitch : 1, 
                duration: (item.duration != undefined && item.duration != '') ? item.duration : 1, 
                sqsUrl: (item.SQS_url != undefined && item.SQS_url != '') ? item.SQS_url : "",
                denoiser: (item.denoiser != undefined && item.denoiser != '') ? item.denoiser : 0,
                input:(item.input != undefined && item.input != '') ? item.input : "" }}
                onSubmit={async (values, { setSubmitting }) => {
                    submitData(values)
                    
                }}
                validationSchema={Yup.object().shape({
                    tts_id: Yup.string()
                        .required("Required"),
                    vocoder_id: Yup.string()
                        .required("Required"),
                    version: Yup.string()
                        .required("Required"),
                    demo_text: Yup.string()
                        .required("Required"),
                    sqsUrl: Yup.string()
                        .required("Required"),
                    input: Yup.string()
                        .required("Required"),
                    pitch: Yup.number()
                        .required("Required"),
                    duration: Yup.number()
                        .required("Required"),
                    denoiser: Yup.number()
                        .required("Required"),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit}>

                                <Modal.Header>
                                    <Modal.Title>Update Speaker: {item.name}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <label htmlFor="exampleInputName">
                                                    Company Code
                                                </label>
                                                <Form.Control
                                                    placeholder="Name"
                                                    type="text"
                                                    disabled
                                                    name="name"
                                                    value={values.companyCode}
                                                ></Form.Control>
                                               
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                        <Form.Group>
                                                <label htmlFor="exampleInputName">
                                                    Speaker Code
                                                </label>
                                                <Form.Control
                                                    placeholder="Name"
                                                    type="text"
                                                    disabled
                                                    name="name"
                                                    value={values.speakerCode}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="5">
                                            <Form.Group>
                                                <label>TTS Id</label>
                                                <Form.Control
                                                    placeholder="tts id"
                                                    type="text"
                                                    name="tts_id"
                                                    value={values.tts_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.tts_id && touched.tts_id && "error"}
                                                ></Form.Control>
                                                {errors.tts_id && touched.tts_id && (
                                                    <div className="input-feedback">{errors.tts_id}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md="5">
                                            <Form.Group>
                                                <label>Vocoder Id</label>
                                                <Form.Control
                                                    placeholder="Vocoder Id"
                                                    type="text"
                                                    name="vocoder_id"
                                                    value={values.vocoder_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.vocoder_id && touched.vocoder_id && "error"}
                                                ></Form.Control>
                                                {errors.vocoder_id && touched.vocoder_id && (
                                                    <div className="input-feedback">{errors.vocoder_id}</div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col md="2">
                                            <Form.Group>
                                                <label>Version</label>
                                                <Form.Control
                                                    placeholder="Version"
                                                    type="text"
                                                    name="version"
                                                    value={values.version}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.version && touched.version && "error"}
                                                ></Form.Control>
                                                {errors.version && touched.version && (
                                                    <div className="input-feedback">{errors.version}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="5">
                                            <Form.Group>
                                                <label>SQS Url</label>
                                                <Form.Select
                                                    placeholder="SQS URL"
                                                    type="text"
                                                    name="sqsUrl"
                                                    value={values.sqsUrl}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={(errors.sqsUrl && touched.sqsUrl && "error")  ? "error form-control" : "form-control"}
                                                >
                                                     <option key="-1" value="">Select SQS</option>
                                                    {sqsList.length > 0 && sqsList.map((item, index) => {
                                                        return (<option key={index} value={item.value}>{item.name}</option>)
                                                    })}
                                                </Form.Select>
                                                {errors.sqsUrl && touched.sqsUrl && (
                                                    <div className="input-feedback">{errors.sqsUrl}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md="5">
                                            <Form.Group>
                                                <label>Input</label>
                                                <Form.Select
                                                    placeholder="IPA"
                                                    type="text"
                                                    name="input"
                                                    value={values.input}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={(errors.input && touched.input) ? "error form-control" : "form-control"}
                                                >
                                                    <option key="0" value="">Select Input</option>
                                                    <option key="1" value="IPA">IPA</option>
                                                    <option key="2" value="NIPA">NON-IPA</option>
                                                </Form.Select>
                                                {errors.input && touched.input && (
                                                    <div className="input-feedback">{errors.input}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md="2">
                                            <Form.Group>
                                                <label>denoiser</label>
                                                <Form.Select
                                                    placeholder="denoiser"
                                                    type="text"
                                                    name="denoiser"
                                                    value={values.denoiser}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={(errors.denoiser && touched.denoiser) ? "error form-control" : "form-control"}
                                                >
                                                    <option key="0" value="">Denoiser</option>
                                                    <option key="1" value="0">0</option>
                                                    <option key="2" value="1">1</option>
                                                </Form.Select>
                                                {errors.denoiser && touched.denoiser && (
                                                    <div className="input-feedback">{errors.denoiser}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col md="8">
                                        <Form.Group>
                                                <label>Demo text</label>
                                                <Form.Control
                                                    placeholder="Demo Text"
                                                    type="text"
                                                    name="demo_text"
                                                    value={values.demo_text}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.demo_text && touched.demo_text && "error"}
                                                ></Form.Control>
                                                {errors.demo_text && touched.demo_text && (
                                                    <div className="input-feedback">{errors.demo_text}</div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col md="2">
                                        <Form.Group>
                                                <label>Pitch</label>
                                                <Form.Control
                                                    placeholder="Pitch"
                                                    type="number"
                                                    name="pitch"
                                                    value={values.pitch}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col md="2">
                                        <Form.Group>
                                                <label>Duration</label>
                                                <Form.Control
                                                    placeholder="Duration"
                                                    type="number"
                                                    name="duration"
                                                    value={values.duration}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="clearfix"></div>



                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={closeModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" >
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>

                        </>
                    );
                }}

            </Formik>
        </Modal>
    );
}

export default UpdateSpeakerModal;