
import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "./layouts/Admin.js";
import Login from "./auth/login";
import Speakers from "./views/Speakers";
import { Toaster } from "react-hot-toast"
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
  <Toaster
  position="top-right"
  reverseOrder={false}
/>
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/admin/speakers/:id" render={(props) => <Speakers {...props} />} />


      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>
  </>
);
