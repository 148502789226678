import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";
import httpClient from 'http-common'
import httpClientV1 from 'http-common-v1'
import * as _ from "lodash";

import config from "constants/config"
import toast from "react-hot-toast"

function AddEditCompanies(props) {
    const { show, onHide, message, setMessage, type, itemData } = props
    const [pan, setPan] = useState(null);
    const [gstIn, setGstIn] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [domain, setDomain] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [countryCode, setCountryCode] = useState('+91');
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [pinCode, setPinCode] = useState(null);
    const [state, setState] = useState(null);
    const [country, setCountry] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);


    const closeModal = () => {
        onHide();
    }

    useEffect(() => {
        console.log("itemData ::: ", itemData, type)
        if (itemData != null) {
            setPan(itemData.pan);
            setGstIn(itemData.gstIn);
            setName(itemData.name);
            setEmail(itemData.email);
            setDomain(itemData.domain)
            setMobile(itemData.mobile)
            setCountryCode(itemData.countryCode)
            setAddress(itemData.address)
            setCity(itemData.city)
            setPinCode(itemData.pinCode)
            setState(itemData.state)
            setCountry(itemData.country)
        }

    }, [itemData, type]);

    const addCompanyDetails = async () => {
        const payloadData = {
            "pan": pan,
            "gstIn": gstIn,
            "name": name,
            "email": email,
            "domain": domain,
            "mobile": String(mobile),
            "countryCode": countryCode,
            "address": address,
            "city": city,
            "pinCode": String(pinCode),
            "state": state,
            "country": country
        }
        for (let key in payloadData) {
            if (payloadData[key] === null) {
                setShowError(true);
                setErrorMessage(`${key} is required!`)
                return;
            }
        }
        if (payloadData.pan.length > 10 || payloadData.pan.length < 10) {
            setShowError(true);
            setErrorMessage(`Pan Number should be 10 digit`)
        } else if (payloadData.gstIn.length > 15 || payloadData.gstIn.length < 15) {
            setShowError(true);
            setErrorMessage(`GST Number should be 15 digit`)
        } else if (payloadData.mobile.length > 10 || payloadData.gstIn.length < 10) {
            setShowError(true);
            setErrorMessage(`Mobile Number should be 10 digit`);
        } else if (payloadData.pinCode.length > 6 || payloadData.pinCode.length < 6) {
            setShowError(true);
            setErrorMessage(`Pin Code Number should be 6 digit`);
        } else {
            if (type === 1) {
                try {
                    const getCompany = await httpClientV1.post(`${config.V1_AUTH_API_URL}/companies`, payloadData);
                    toast.success("Company Created successfully");
                    closeModal()
                } catch (error) {
                    toast.error(error?.message);

                }
            } else {
                try {
                    const getCompany = await httpClientV1.patch(`${config.V1_AUTH_API_URL}/companies/${itemData.uuid}`, payloadData);
                    toast.success("Company Update successfully");
                    closeModal()
                } catch (error) {
                    toast.error(error?.message);

                }
            }
        }
    }


    return (

        <Modal {...props} size="lg" backdrop="static" >


            <Modal.Header>
                <Modal.Title>Add/Edit Company</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Pan Number
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                minlength="10"
                                maxlength="10"
                                name="dubbing_limit"
                                value={pan}
                                onChange={(v) => {
                                    setShowError(false);
                                    setPan(v.target.value)
                                }}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                GST Number
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                minlength="15"
                                maxlength="15"
                                name="file_size"
                                value={gstIn}
                                onChange={(v) => {
                                    setShowError(false);
                                    setGstIn(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Name
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                name="file_size"
                                value={name}
                                onChange={(v) => {
                                    setShowError(false);
                                    setName(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Email
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="email"
                                name="dubbing_limit"
                                value={email}
                                onChange={(v) => {
                                    setShowError(false);
                                    setEmail(v.target.value)
                                }}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Domain
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                name="file_size"
                                value={domain}
                                onChange={(v) => {
                                    setShowError(false);
                                    setDomain(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Mobile
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="number"
                                name="file_size"
                                value={mobile}
                                min="10"
                                max="10"
                                onChange={(v) => {
                                    setShowError(false);
                                    setMobile(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Address
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                name="dubbing_limit"
                                value={address}
                                onChange={(v) => {
                                    setShowError(false);
                                    setAddress(v.target.value)
                                }}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                City
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                name="file_size"
                                value={city}
                                onChange={(v) => {
                                    setShowError(false);
                                    setCity(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Pin Code
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="number"
                                name="file_size"
                                min="6"
                                max="6"
                                value={pinCode}
                                onChange={(v) => {
                                    setShowError(false);
                                    setPinCode(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                State
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                name="dubbing_limit"
                                value={state}
                                onChange={(v) => {
                                    setShowError(false);
                                    setState(v.target.value)
                                }}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Country
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                name="file_size"
                                value={country}
                                onChange={(v) => {
                                    setShowError(false);
                                    setCountry(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>

                </Row>


                {showError && <p class="error-class">{errorMessage}</p>}
                <div className="clearfix"></div>

            </Modal.Body>


            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={addCompanyDetails} >{type === 1 ? 'Save' : 'Update'}
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default AddEditCompanies;