import httpClientV1 from 'http-common-v1';
import * as _ from "lodash";
import { useEffect, useState } from 'react';
import {
    Button, Col, Form, Modal, Row
} from "react-bootstrap";

import config from "constants/config";
import toast from "react-hot-toast";

function AddEditSpeaker(props) {
    const { show, onHide, message, setMessage, type, itemData } = props


    const [voiceName, setVoiceName] = useState(null);
    const [voiceId, setVoiceId] = useState(null);
    const [url, setUrl] = useState(null);
    const [accent, setAccent] = useState(null);
    const [gender, setGender] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [showError, setShowError] = useState(false);
    const [aiModel, setAiModel] = useState('eleven_multilingual_v2');
    const [voiceProvide, setVoiceProvide] = useState('https://api.elevenlabs.io/v1');




    useEffect(() => {
        console.log("itemData ::: ", itemData, type)
        if (itemData != null && type != 3) {
            setVoiceName(itemData.name);
            setVoiceId(itemData.voiceId);
            setUrl(itemData.url);
            setGender(itemData.gender);
            setAccent(itemData.accentId)
        }

    }, [itemData, type]);
    const getAllLangues = async () => {
        try {
            const response = await httpClientV1.get(config.V1_AUTH_API_URL + '/languages?page=1&limit=10');
            setLanguages(response.data.data.langauges)
        } catch (error) {
        }
    }

    const addVoiceDetails = async () => {
        if (voiceName === null || voiceId === null || accent === null || gender === null) {
            setShowError(true);
        } else {
            const findAssent = _.find(languages, (v) => v.id === accent);
            const payload = {
                "name": voiceName,
                "sourceId": "74bbeae8-05ca-405a-8110-d9cd94ec88a6",
                "url": url != null && url != '' ? url : "Josh_m_TxGEqnHWrfWFTfGW9XjX_en.mp3",
                "accentId": findAssent.uuid,
                "voiceId": voiceId,
                "gender": gender,
                "sources": "elevenlabs",
                "imageUrl": "Josh_m_TxGEqnHWrfWFTfGW9XjX_en.jpg",
                "modelDetail": aiModel,
                "voiceProvider": voiceProvide

            }
            if (type === 1 || type === 3) {
                try {
                    if (type === 3) {
                        payload.companyId = itemData.uuid;
                    }
                    const getAllVoice = await httpClientV1.post(`${config.V1_VIDEO_API_URL}/voice`, payload);
                    toast.success("Voice Created successfully");
                    closeModal()
                } catch (error) {
                    toast.error(error?.message);

                }
            } else {
                try {
                    const getAllVoice = await httpClientV1.patch(`${config.V1_VIDEO_API_URL}/voice/${itemData.uuid}`, payload);
                    toast.success("Voice Update successfully");
                    closeModal()
                } catch (error) {
                    toast.error(error?.message);

                }
            }
        }

    }


    const closeModal = () => {
        onHide();
    }


    return (

        <Modal {...props} size="lg" backdrop="static" onShow={getAllLangues}>

            <Modal.Body>

                <Row>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Voice Name
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                name="dubbing_limit"
                                value={voiceName}
                                onChange={(v) => {
                                    setShowError(false);
                                    setVoiceName(v.target.value)
                                }}
                            ></Form.Control>

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Voice Id
                            </label>
                            <Form.Control
                                placeholder="Voice id"
                                type="text"
                                name="file_size"
                                value={voiceId}
                                onChange={(v) => {
                                    setShowError(false);
                                    setVoiceId(v.target.value)
                                }}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <label>Model</label>
                            <Form.Select
                                placeholder="Select Model"
                                type="text"
                                name="company"
                                className="form-control"
                                value={aiModel}
                                onChange={(v) => {
                                    setShowError(false);
                                    setAiModel(v.target.value)
                                }}
                            >
                                <option key="-1" value="">Select </option>
                                {config.AI_MODELS.length > 0 && config.AI_MODELS.map((v, index) => {
                                    return (<option key={index} value={v.value}>{v.key}</option>)
                                })}

                            </Form.Select>
                        </Form.Group>
                    </Col>


                    {/* <Col>
                        <Form.Group>
                            <label>Voice Provide</label>
                            <Form.Select
                                placeholder="Select Voice provider"
                                type="text"
                                name="company"
                                className="form-control"
                                value={voiceProvide}
                                onChange={(v) => {
                                    setShowError(false);
                                    setVoiceProvide(v.target.value)
                                }}
                            >

                                <option key="-1" value="">Select Accent</option>
                                {languages.length > 0 && languages.map((v, index) => {
                                    return (<option key={index} value={v.id}>{v.title}</option>)
                                })}

                            </Form.Select>
                        </Form.Group>
                    </Col> */}

                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <label>Accent</label>
                            <Form.Select
                                placeholder="Select accent"
                                type="text"
                                name="company"
                                className="form-control"
                                value={accent}
                                onChange={(v) => {
                                    setShowError(false);
                                    setAccent(v.target.value)
                                }}
                            >

                                <option key="-1" value="">Select Accent</option>
                                {languages.length > 0 && languages.map((v, index) => {
                                    return (<option key={index} value={v.id}>{v.title}</option>)
                                })}

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label>Gender</label>
                            <Form.Select
                                placeholder="Select gender"
                                type="text"
                                name="company"
                                className="form-control"
                                value={gender}
                                onChange={(v) => {
                                    setShowError(false);
                                    setGender(v.target.value)
                                }}
                            >
                                <option key="-1" value="">Select Gender</option>
                                <option key="-1" value="male">Male</option>
                                <option key="-1" value="female">Female</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Url
                            </label>
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                name="file_size"
                                value={url}
                                onChange={(v) => setUrl(v.target.value)}

                            ></Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                {showError && <p class="error-class">Please provide voice name, voice id, accent and gender. </p>}
                <div className="clearfix"></div>

            </Modal.Body>


            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={addVoiceDetails} >{type === 1 ? 'Save' : 'Update'}
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default AddEditSpeaker;