
// import Speakers from "./views/Speakers";

// import Email from "./views/Email";
import V1Companies from "./views/V1Companies";
import V1Speakers from "./views/V1Speakrs";
import V1Videos from "./views/V1Videos";








const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/company",
  //   name: "Company's",
  //   icon: "nc-icon nc-notes",
  //   component: Companies,
  //   layout: "/admin"
  // },

  // {
  //   path: "/leads",
  //   name: "Leads",
  //   icon: "nc-icon nc-email-83",
  //   component: Leads,
  //   layout: "/admin"
  // },

  // {
  //   path: "/translator",
  //   name: "Translator",
  //   icon: "nc-icon nc-bullet-list-67",
  //   component: Translator,
  //   layout: "/admin"
  // },
  // {
  //   path: "/videos",
  //   name: "Videos",
  //   icon: "nc-icon nc-note-03",
  //   component: Videos,
  //   layout: "/admin"
  // },
  // {
  //   path: "/public/videos",
  //   name: "Public Videos",
  //   icon: "nc-icon nc-tv-2",
  //   component: PublicVideos,
  //   layout: "/admin"
  // },

  {
    path: "/v1/videos",
    name: "Videos",
    icon: "nc-icon nc-note-03",
    component: V1Videos,
    layout: "/admin"
  },

  {
    path: "/v1/speakers",
    name: "Speakers",
    icon: "nc-icon nc-notification-70",
    component: V1Speakers,
    layout: "/admin"
  },

  {
    path: "/v1/companies",
    name: "Companies",
    icon: "nc-icon nc-notes",
    component: V1Companies,
    layout: "/admin"
  },
];

export default dashboardRoutes;
