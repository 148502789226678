import axios from "axios";
import { useHistory } from "react-router-dom";

const httpClient = axios.create({
  baseURL: process.env.BASEURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '47d64e2e-e96e-11ed-a05b-0242ac120003',
  },
});

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

let isRefreshing = false;
let refreshSubscribers = [];

// Helper function to add subscribers
function onRefreshed(token) {
  refreshSubscribers.map((callback) => callback(token));
}

// Helper function to subscribe to token refresh
function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

httpClient.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalRequest = error.config;
    let history = useHistory();

    if (error?.response?.status === 401) {
      // originalRequest._retry = true;

      // Check if we are already refreshing the token
      if (isRefreshing) {
        // Subscribe to refresh process
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(httpClient(originalRequest));
          });
        });
      }

      // Set refreshing state
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          history.push('/login');
          return Promise.reject(error);
        }

        // Call refresh token API
        const { data } = await axios.post(`${process.env.BASEURL}/auth/refresh-token`, {
          refreshToken,
        });

        const newToken = data.accessToken;
        localStorage.setItem('token', newToken);

        // Notify all subscribers with the new token
        onRefreshed(newToken);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return httpClient(originalRequest);
      } catch (err) {
        // If refresh token fails, log the user out
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        history.push('/login');
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
        refreshSubscribers = [];
      }
    }

    return Promise.reject(error);
  }
);

export default httpClient;
