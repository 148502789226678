import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import AddUpdateVoiceInVideo from 'components/Shared/AddUpdateVoiceInVideo';
import V1AssignedTranslatorOrQA from 'components/Shared/V1AssignedTranslatorOrQA';
import config from "constants/config";
import httpClient from 'http-common';
import httpClientV1 from 'http-common-v1';
import * as _ from "lodash";
import { useEffect, useState } from "react";
import Pagination from 'react-bootstrap/Pagination';
import toast from "react-hot-toast";


// react-bootstrap components
import * as moment from "moment";
import {
    Button, Card, Col, Container, Dropdown, Form, Row, Table
} from "react-bootstrap";

function V1Videos() {
    const [videos, setVideos] = useState([]);
    const [filterValue, setFilterValue] = useState('NOT_ASSIGNED');
    const [searchValue, setSearchValue] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false);



    const [type, setType] = useState(1);
    const [selectItem, setSelectItem] = useState('');
    const [targetLang, setTargetLang] = useState(config.LANGUAGES_ASSENTS);
    const [sourceLang, setSourceLang] = useState(config.LANGUAGES_ASSENTS_SOURCE)
    const [totalVideoCount, setVideoCount] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [item, setItem] = useState([])
    let numberOfPages = 0;
    let isShow = false;
    let active = 1;


    const getApiCallPage = async (value) => {
        await setPage(value);
        await getAllVideos(value);
    }

    function delay(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }



    const getAllVideos = async (value) => {
        try {
            // const getCompany = await httpClient.patch(config.API_URL + "/dashboard/dubbed/videos")

            let payload = {}
            const targetLangValue = []
            _.each(targetLang, (v) => {
                if (v.selected) {
                    targetLangValue.push(v.value)
                }
            })
            const sourceLangValue = []
            _.each(sourceLang, (v) => {
                if (v.selected) {
                    sourceLangValue.push(v.value)
                }
            });
            if (targetLangValue.length > 0) {
                payload = {
                    ...payload,
                    'dubbedLanguages': targetLangValue
                }
            }
            if (sourceLangValue.length > 0) {
                payload = {
                    ...payload,
                    'originalLanguages': sourceLangValue
                }
            }
            if (searchValue !== "") {
                payload.search = searchValue;
            }
            const getAllVideos = await httpClientV1.post(config.V1_VIDEO_API_URL + `/videos/all?page=${(value != "") ? value : page}&limit=${limit}&reviewStatus=` + filterValue, payload)
            const responseData = getAllVideos.data.data;
            setVideoCount(responseData.totalCount);
            const activeValue = (value != "") ? value : page;
            mapPagination(responseData.totalCount, activeValue)
            setVideos(responseData.videos);
            isShow = true;
            console.log("videos :::::: ", videos)

        } catch (error) {
            console.log(error)
        }

    }

    const mapPagination = (totalCount, active) => {
        numberOfPages = Math.ceil(totalCount / limit);
        let items = []
        for (let number = 1; number <= numberOfPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={() => getApiCallPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setItem(items);
    }

    const updateTransliterated = async (value, id) => {
        console.log(value, id)
        try {
            const getCompany = await httpClientV1.patch(`${config.V1_VIDEO_API_URL}/videos/${id}`, {
                isTransliteratedOn: value,
            })
            toast.success("Transliteration update successfully");
        } catch (error) {
            toast.error("Something is wrong! Please try again sometime later.", error.message);
        }

    }


    const assignedTranslator = async (value, tatDate, selectItem) => {
        try {
            console.log()
            const typeData = (type === 1) ? 'translator' : 'QA';
            const getTatDate = new Date(tatDate);

            if (type === 1) {
                const getCompany = await httpClientV1.post(`${config.V1_VIDEO_API_URL}/videos/${selectItem.uuid}/translator/${value.uuid}/assign`, {
                    isRequested: true,
                    reviewTargetDate: getTatDate.toISOString()
                })

            } else {
                const getCompany = await httpClientV1.post(`${config.V1_VIDEO_API_URL}/videos/${selectItem.uuid}/qa/${value.uuid}/assign`, {
                    qaCompletedDate: getTatDate.toISOString(),
                })
            }
            getAllVideos('', 0)
            setSelectItem('')
            toast.success("Assign successfully");
        } catch (error) {
            toast.error("Something is wrong! Please try again sometime later.");
        }
    }

    const checkBoxHanlde = (event, index) => {
        setTargetLang([])
        if (event.target.checked) {
            const trgLang = targetLang;
            trgLang[index].selected = true
            setTimeout(() => {
                setTargetLang(trgLang)

            }, 100);

        } else {
            const trgLang = targetLang;
            trgLang[index].selected = false
            setTimeout(() => {
                setTargetLang(trgLang)

            }, 100);
        }

    }
    const secondsToMinutesAndSeconds = (seconds) => {
        var minutes = Math.floor(seconds / 60);
        var remainingSeconds = Math.round(seconds % 60);

        return `${minutes}m:${remainingSeconds}s`
    }
    const checkBoxHandleSource = (event, index) => {
        setSourceLang([])
        if (event.target.checked) {
            const srcLang = sourceLang;
            srcLang[index].selected = true
            setTimeout(() => {
                setSourceLang(srcLang)

            }, 100);

        } else {
            const srcLang = sourceLang;
            srcLang[index].selected = false
            setTimeout(() => {
                setSourceLang(srcLang)

            }, 100);
        }

    }
    const setSelectedValue = (value) => {
        setTimeout(() => {
            setFilterValue(value);
        }, 100);
    }

    const updateVideoStatus = async (selectValue, id) => {
        const token = localStorage.getItem('token')
        try {
            const getCompany = await httpClient.put(config.API_URL + "/admin/dashboard/dubbed/video/" + id, { status: selectValue });
            if (getCompany.status === 200) {
                toast.success("Video status update successfully.")
                getAllVideos('', 0)
            }
        } catch (error) {
            toast.error("Something wrong!. Try again after sometime", error.message)
            console.log(error)
        }
    }






    useEffect(() => {
        getAllVideos('', 0);
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover table-c">
                            <Card.Header>
                                <Row className="table-header-top">
                                    <Card.Title as="h4" className="card_title">Videos list</Card.Title>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                    <Col xs={5}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control value={searchValue} type="text" placeholder="search by title" onChange={(v) => {
                                                setPage(1)
                                                setSearchValue(v.target.value)
                                            }} />
                                        </Form.Group>

                                    </Col>
                                    <Col >

                                        <Form.Select className="cart_title_button" aria-label="Default select example" value={filterValue} onChange={async (v) => {
                                            setPage(1)
                                            setSelectedValue(v.target.value);
                                        }}>
                                            <option value="">Please Select filter</option>
                                            {config.VIDEO_STATUS.map((v) => {
                                                return <option value={v.value}>{v.name}</option>
                                            })}

                                        </Form.Select>
                                    </Col>
                                    <Col >
                                        <Dropdown className="d-inline mx-2" autoClose="outside">
                                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                                Source Lang
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {sourceLang.length > 0 && sourceLang.map((v, index) => {
                                                    return (<Dropdown.Item ><input type="checkbox" onClick={(event) => {
                                                        setPage(1)
                                                        checkBoxHandleSource(event, index)
                                                    }} checked={v.selected} style={{ marginRight: "10px" }} ></input>{v.text}</Dropdown.Item>)
                                                })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col >
                                        <Dropdown className="d-inline mx-2" autoClose="outside">
                                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                                Target Lang
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {targetLang.length > 0 && targetLang.map((v, index) => {
                                                    return (<Dropdown.Item ><input type="checkbox" onClick={(event) => {
                                                        setPage(1)
                                                        checkBoxHanlde(event, index)
                                                    }} checked={v.selected} style={{ marginRight: "10px" }} ></input>{v.text}</Dropdown.Item>)
                                                })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col >
                                        <Button variant="primary" onClick={() => getAllVideos('', 0)} >Submit</Button>
                                    </Col>

                                </Row>

                            </Card.Header>
                            <Card.Body className="table_video table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">No</th>
                                            <th className="border-0">Id</th>
                                            <th className="border-0">Title</th>
                                            <th className="border-0">Voice Id</th>
                                            <th className="border-0">Source Language</th>
                                            <th className="border-0">Target Language</th>
                                            <th className="border-0">Translator Assigned</th>
                                            <th className="border-0">QA Assigned</th>
                                            <th className="border-0">Video Status</th>
                                            <th className="border-0">Status</th>
                                            <th className="border-0">Transliteration</th>
                                            <th className="border-0">Duration</th>
                                            <th className="border-0">Review Request time</th>

                                            <th className="border-0">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {videos.length > 0 &&
                                            videos.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{(index + 1)}</td>

                                                        {/* <td>{item.translator}</td> */}
                                                        <td>{item.uuid}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item?.speakerDetails?.voiceId}</td>
                                                        <td>{item.sourceLanguage}</td>
                                                        <td>{item.targetLanguage}</td>
                                                        <td>{item?.reviewInfo?.translatorName}</td>
                                                        <td>{item?.reviewInfo?.qaName}</td>
                                                        <td>{item?.reviewInfo?.status}</td>
                                                        <td>{item.status}</td>
                                                        <td><BootstrapSwitchButton checked={item.isTransliteratedOn} size="xs" onChange={(checked) => {
                                                            const value = checked;
                                                            updateTransliterated(value,
                                                                item.uuid)
                                                        }
                                                        } /></td>
                                                        <td>{secondsToMinutesAndSeconds(item.duration)}</td>
                                                        {item?.reviewInfo?.reviewTargetDate === null && <td>---</td>}

                                                        {item?.reviewInfo?.reviewTargetDate !== null && <td>{moment(item?.reviewInfo?.reviewTargetDate).format("DD-MM-YY")}</td>}

                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                                    Action
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={
                                                                        () => {
                                                                            setType(1);
                                                                            setSelectItem(item);
                                                                            setModalShow(true);
                                                                        }
                                                                    } >Assigned Translator</Dropdown.Item>
                                                                    <Dropdown.Item onClick={
                                                                        () => {
                                                                            setType(2);
                                                                            setSelectItem(item);
                                                                            setModalShow(true);
                                                                        }
                                                                    }
                                                                    >Assigned QA</Dropdown.Item>
                                                                    <Dropdown.Item onClick={
                                                                        () => {
                                                                            setType(1);
                                                                            setSelectItem(item);
                                                                            setUpdateModalShow(true);
                                                                        }
                                                                    }
                                                                    >Update Voice</Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>)

                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {item && <Row className="paginationValue">
                    <Pagination>{item}</Pagination>
                </Row>}



            </Container>


            {modalShow && selectItem !== '' && <V1AssignedTranslatorOrQA type={type} show={modalShow} itemData={selectItem} onHide={async (type, value, tatDate) => {
                if (type === 1) {
                    assignedTranslator(value, tatDate, selectItem);
                    setSelectItem('')
                    setModalShow(false);
                } else {
                    setSelectItem('')
                    setModalShow(false)
                }
            }}
            ></V1AssignedTranslatorOrQA>}

            {updateModalShow && selectItem !== '' && <AddUpdateVoiceInVideo type={type} show={updateModalShow} itemData={selectItem} onHide={async (type, value, tatDate) => {
                if (type === 1) {
                    setSelectItem('')
                    setUpdateModalShow(false);
                } else {
                    setSelectItem('')
                    setUpdateModalShow(false)
                }
            }}></AddUpdateVoiceInVideo>}

        </>
    );
}

export default V1Videos;