import React, { useState, useEffect } from "react";

import axios from "axios";
import config from "constants/config"
import toast from "react-hot-toast"
import * as _ from "lodash"
import httpClient from 'http-common'
import { useLocation, useHistory } from "react-router-dom";
import UpdateSpeakerModal from 'components/Shared/UpdateSpeaker'
// react-bootstrap components
import {
    Card,
    Form,
    Table,
    Container,
    Row,
    Col,
    Dropdown
} from "react-bootstrap";
import { isAwaitExpression } from "typescript";

function Speakers() {
    let history = useHistory();

    const [company, setCompany] = useState([]);
    const [speaker, setSpeaker] = useState([]);
    const [allSpeaker, setAllSpeaker] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectCompany, setSelectCompany] = useState();
    const [selectCompanyName, setSelectCompanyName] = useState('');
    const [search, setSearch] = useState();




    const getAllCompany = async () => {
        const token = localStorage.getItem('token')
        try {
            const getCompany = await httpClient.get(config.API_URL + "/admin/companies")
            setCompany(getCompany.data.data)

        } catch (error) {
            console.log(error)
        }

    }

    const charLimit = (text) => {
        const textLimit = 20
        if(text.length <= textLimit){
          return text;
        }else {
          let result = text.substring(0, textLimit);
          return result+"..."
        }
      }

    const getAllSpeaker = async (id) => {
        const token = localStorage.getItem('token')
        try {
            const getSpeakerDetails = await httpClient.get(config.API_URL + "/admin/voices?company="+id+"&page=1&limit=100")
            await setSpeaker(getSpeakerDetails.data.data.data);
            await setAllSpeaker(getSpeakerDetails.data.data.data)
            console.log("speaker ::::", speaker)

        } catch (error) {
            console.log(error)
        }

    }

    const updateStatus = async (speakerDetails) => {
       
        const token = localStorage.getItem('token')
        await setSelectedRow(speakerDetails)
        await setModalShow(true)

    }

    const startAutomate = async (speakerDetails) => {
        try {
            const getSpeakerDetails = await httpClient.get(config.API_URL + "/admin/automate/voice/"+speakerDetails._id+"/company/"+speakerDetails.company);
            console.log("speaker ::::", getSpeakerDetails)
            toast.success("Process start successfully")
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === undefined && token === null) {
            history.push('/login')
        }
        getAllCompany()
    }, []);

    return (
        <>
            <Container fluid>
                <Row >
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Row className="table-header-top">
                                { selectCompanyName === '' && <Card.Title as="h4">Speaker List</Card.Title>}
                                    { selectCompanyName != '' && <Card.Title as="h4">{selectCompanyName} :- Speakers list</Card.Title>}
                                    <div className="search_box">
                                    <Form.Group>
                                            <label>Search</label>
                                            <Form.Control
                                                placeholder="Search "
                                                type="text"
                                                name="search"
                                                className="form-control"
                                                value={search}
                                                onChange={ async (e) => {
                                                    const itemV = e.target.value;
                                                    setSearch(itemV);
                                                    const getAllSpeaker = allSpeaker;
                                                    const filterResult = []
                                                    _.each(getAllSpeaker, (v)=> {
                                                        let result = v.name.includes(itemV);
                                                        let idInclude = v._id.includes(itemV);
                                                        if(result){
                                                            filterResult.push(v);
                                                        }else if(idInclude){
                                                            filterResult.push(v);
                                                        }
                                                    });
                                                   await setSpeaker(filterResult);
                                                }}>                                                
                                            </Form.Control>
                                        </Form.Group>

                                    </div>
                                    
                                </Row>
                                <Row>
                                    <Col md="4" >
                                        <Form.Group>
                                            <label>Company</label>
                                            <Form.Select
                                                placeholder="Select Company"
                                                type="text"
                                                name="company"
                                                className="form-control"
                                                value={selectCompany}
                                                onChange={(e) => {
                                                    const itemV = e.target.value;
                                                    const allCompany = company;
                                                    const singleCompany = _.find(allCompany, (v) => {
                                                        return v._id === itemV
                                                    }) 
                                                    setSelectCompany(itemV);
                                                    setSelectCompanyName(singleCompany.name)
                                                    getAllSpeaker(itemV);
                                                }}
>
                                                <option key="-1" value="">Select Company</option>
                                                {company.length > 0 && company.map((item, index) => {
                                                    return (<option key={index} value={item._id}>{item.name}</option>)
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">Voice Id</th>
                                            <th className="border-0">Name</th>
                                            <th className="border-0">Accent</th>
                                            <th className="border-0">Pitch</th>
                                            <th className="border-0">Duration</th>
                                            <th className="border-0">Status</th>
                                            <th className="border-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {speaker.length > 0 && speaker.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                            <td>{item._id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.accent}</td>
                                            <td>{item.pitch}</td>
                                            <td>{item.duration}</td>
                                            <td>{item.status}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                        Action
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={ () => updateStatus(item)}>Update Status</Dropdown.Item>
                                                        {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                            )

                                        })}
                                        
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            { selectedRow != null && <UpdateSpeakerModal setSelectedRow={setSelectedRow} item={selectedRow} show={modalShow} onHide={(number) => {
                if(number === 1){
                    getAllSpeaker(selectCompany);
                    setModalShow(false)
                }else {
                    setModalShow(false)
                }
                }} />}

            
        </>
    );
}

export default Speakers;