import React, { useState } from 'react';
import {
    Modal,
    Button,
} from "react-bootstrap";
import httpClient from 'http-common'

function ConfirmModal(props) {
    const {show, onHide, message , setMessage} = props

    const closeModal = (value) => {
        setMessage('')
        onHide(value);
    }

    return ( 

        <Modal {...props} backdrop="static">
           <Modal.Header>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => closeModal(0)}>
            No
          </Button>
          <Button variant="primary" onClick={() => closeModal(1)} >
            Yes
          </Button>
        </Modal.Footer>
        </Modal>
        
     );
}

export default ConfirmModal;