import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import config from "../constants/config";

// react-bootstrap components
import {
  Button,
  Card, Col, Container, Form, Row
} from "react-bootstrap";
import { useHistory } from 'react-router-dom';

function Login() {
  let history = useHistory()
  const [isOtp, setIsOtp] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [otp, setOtp] = useState(null);
  const [isOtpBlank, setIsOtpBlank] = useState(false);
  const [isResend, setIsResend] = useState(false);


  const submitOtp = async () => {
    if (otp != "" && otp != null) {

      setIsOtpBlank(false)

      const otpValid = {
        "countryCode": "+91",
        "mobile": mobile,
        "otp": otp
      }

      try {
        const loginDetails = await axios.post(config.V1_AUTH_API_URL + '/login', otpValid);
        const { data, status } = loginDetails;
        localStorage.setItem("accessToken", data.data.tokens.accessToken);
        localStorage.setItem("refreshToken", data.data.tokens.refreshToken);
        history.push('/admin/v1/videos')
      } catch (error) {
        console.log("error ::: ", error)
        toast.error(error.response.data.message)
      }
    } else {
      setIsOtpBlank(true);
    }
  }
  const disabledText = () => {
    setTimeout(() => {
      setIsResend(true)
    }, 60000);

  }
  const login = async (values) => {
    try {
      const loginDetails = await axios.post(config.V1_AUTH_API_URL + '/otp', {
        "countryCode": "+91",
        "mobile": values.mobile,
        "otpType": "login"
      });
      const { data, status } = loginDetails;
      setMobile(values.mobile);
      setIsOtp(true);
      disabledText();
    } catch (error) {
      console.log("error ::: ", error)
      toast.error(error.response.data.message)
    }
  }

  const resendOpt = async (values) => {
    try {
      const email = localStorage.getItem("email")
      const loginDetails = await axios.post(config.V1_AUTH_API_URL + '/login', {
        "countryCode": "+91",
        "mobile": mobile,
        "otp": otp
      });
      const { data, status } = loginDetails;

      setIsOtp(true);
      setIsResend(false);
      disabledText();
      toast.success("Otp Send successfully. Please check you email")
    } catch (error) {
      console.log("error ::: ", error)
      toast.error(error.response.data.message)
    }
  }
  useEffect(() => {
    // disabledText();
  }, []);

  const validationSchema = Yup.object({
    mobile: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
  });


  return (
    <>

      <Container className="">
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mt-5">
              <Card.Header>
                <Card.Title as="h4">Log In</Card.Title>
              </Card.Header>
              <Card.Body>
                {!isOtp && <Formik
                  initialValues={{ mobile: "" }}
                  onSubmit={async (values, { setSubmitting }) => {
                    console.log("Logging in", values);
                    login(values)

                  }}
                  validationSchema={validationSchema}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    } = props;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Enter a mobile number
                              </label>
                              <Form.Control
                                placeholder="Email"
                                type="text"
                                name="mobile"
                                value={values.mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.mobile && touched.mobile && "error"}
                              ></Form.Control>
                              {errors.mobile && touched.mobile && (
                                <div className="input-feedback">{errors.mobile}</div>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="btn-fill pull-right mt-4"
                          type="submit"
                          variant="info"
                        >
                          Log In
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    );
                  }}
                </Formik>}

                {isOtp &&
                  <Form>
                    <Row>
                      <Col >
                        <Form.Group>
                          <label>OTP</label>
                          <Form.Control
                            placeholder="Otp"
                            type="password"
                            name="password"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value)
                            }}
                          ></Form.Control>
                          {isOtpBlank &&
                            <div className="input-feedback">Otp required!</div>
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row  >
                      {isResend && <a className="resend-enabled" onClick={() => resendOpt()} >Resend OTP</a>}
                      {!isResend && <a className='resend-disabled' >Resend OTP</a>}
                    </Row>
                    <Button
                      className="btn-fill pull-right mt-4 mr-4"
                      variant="Success"
                      onClick={() => setIsOtp(false)}
                    >
                      Back
                    </Button>

                    <Button style={{ marginLeft: "10px" }}
                      className="btn-fill pull-right mt-4"
                      variant="info"
                      onClick={submitOtp}
                    >
                      Validate
                    </Button>
                    <div className="clearfix"></div>
                  </Form>}



              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

export default Login;